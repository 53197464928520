:root {
    --PrimaryColor: hsl(199, 100%, 33%);
    --SecondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyColor: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 240, 246);
    --cardBG: rgb(225, 225, 235);
  }


  .header{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--whiteColorDeam);
    width: 100%;
    padding: 1rem;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);

    // .logo{
    //     color: var(--blackColor);
    //     font-weight: 600;
    //     cursor: pointer;
    //     align-items: center;
    //     justify-content: center;

    //     .icon{
    //         font-size: 25px;
    //         color: var(--PrimaryColor);
    //         transform: translateY(5px);
    //     }
    // }

    @media screen and (max-width: 768px){
        .Navbar{
            position: absolute;
            background: var(--whiteColorDeam);
            height: max-content;
            width: 80%;
            border-radius: 1rem;
            top:  -500rem;
            left: 50%;
            padding: 1rem;
            transform: translate(-50%);
            z-index: 2000;
            box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
            transition: .5s ease-in-out;


            .navLists{
                flex-direction: column;
                justify-content: center;
                width: 100%;
                margin: auto;
                padding: .5rem 0;

                .navItem{
                    padding: .5rem 0;

                    .navLink{
                        color: var(--textColor);
                        font-size: .9rem;
                        font-weight: 600;
                        &:hover{
                            color: var(--PrimaryColor);
                        }
                    }
                }
                .btn{
                    margin-top: 1rem;

                    a{
                        font-weight: 600;
                        color: var(--whiteColor);
                        
                    }
                }
            }
            .closeNavbar{
                position: absolute;
                top: 1rem;
                right: 1.5rem;
                color: var(--PrimaryColor);
                &:hover{
                    color: var(--SecondaryColor);
                }                
                  .header .navbar .navLists .navItem .navLink {
                    color: var(--textColor);
                    padding: 0 0.45rem;
                    font-size: 0.9rem;
                    font-weight: 700;
                  }
            }
        }

        .activeNavbar{
            top: 7rem;
        }
    }

    .toggleNavbar{
        .icon{
            font-size: 25px;
            color: var(--PrimaryColor);
        }
        &:hover.icon{
            color: var(--SecondaryColor);

        }
    }
  }

  @media screen and (min-width: 769px) {

    .closeNavbar {
        display: none;
      }
      
      svg.icon {
        display: none;
      }
    .header{
        padding: 1.5rem 2rem;

        .navbar{
            .navLists{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;

                .navItem{
                    .navLink{
                        color: var(--textColor);
                        padding: 0 0.45rem;
                        font-size: 0.9rem;
                        font-weight: 700;

                        &:hover{
                            color: var(--PrimaryColor);
                        }
                    }
                }

                .btn{
                     margin-left: 1rem;
                     a{
                        color: var(--whiteColor);
                        font-weight: 700;
                     }   
                }
            }
        }
    }
  }

  @media screen and (min-width: 960px) {
    .header{
        .navBar{
            .navLists{
                .navItem{
                    padding: 0 0.7rem;
                }
            }
        }
    }
  }

  @media screen and (min-width: 1240px) {
   .header{
    padding: 1rem 8.5rem;
   }
  }