:root {
    --PrimaryColor: hsl(199, 100%, 33%);
    --SecondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyColor: hsl(190, 190, 190);
    --inputColor: hsl(239, 239, 239);
    --bodyColor: hsl(240, 240, 246);
    --cardBG: hsl(225, 225, 235);
  }


  .home{
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content:center;
    margin: auto;

    .overlay{
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgb(47, 106, 127);
        top: 0;
        bottom: 0;
        z-index: 1;
        mix-blend-mode: hard-light;
    }

    video{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        object-fit: cover;
    }

    .homeContent{
        width: 100%;
        height: max-content;
        padding: 8rem 1.5rem 3rem;
        display: flex;
        row-gap: 3rem;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        margin: auto;
        color: var(--whiteColor);
        z-index: 100;

        .textDiv{
            padding: 2rem 0;


            .smallText{
                font-size: 13px;
                text-transform: uppercase;
            }

            .homeTitle{
                position: relative;
                font-size: 1.8rem;
            }
        }

        .cardDiv{
            position: relative;
            padding: 2rem 1rem;
            background: var(--whiteColor);
            border-radius: 10px;
            gap: 1rem;

            lable{
                display: block;
                color: var(--textColor);
                padding-bottom: 10px;
                font-size: 13px;
                font-weight: 500;
            }

            .label_total{
                justify-content: space-between;
                align-items: center;

                .total{
                    color: var(--textColor);
                    font-size: 18px;
                    padding-bottom: 10px;
                }
            }

            .input{
                height: 40px;
                padding: 0 1rem;
                background-color: var(--inputColor);
                border-radius: 3rem;

                input{
                    background: none;
                    border: none;
                    outline: none;
                    width: 100%;
                    font-size: 13px;
                    font-weight: 500;
                    &::placeholder{
                        font-size: 13px;
                    }
                }

                .icon{
                    font-size: 18px;
                    color: var(--PrimaryColor)
                    !important;
                    margin-left: 10px;
                }

                .input[type=range]{
                    background: blue;
                }
            }

            .searchOptions{
                position: absolute;
                width: max-content;
                bottom: -0.7rem;
                column-gap: .8rem;
                padding: 5px 1rem;
                border-radius: 3rem;
                left: 50%;
                transform: translate(-50%);
                justify-content: center;
                cursor: pointer;



             

                .icon{
                    font-size: 18px;
                }

                span{
                    font-size: 13px;
                }
            }



        }

        .homeFootericons{
            width: 100%;
            justify-content:space-between ;


            .icon{
                font-size: 20px;
                margin: 0 5px;
                color: var(--whiteColor);
                font-weight: 600;
                &:hover{
                    color: var(--PrimaryColor);

                }
            }
        }  
    }
  }

   

  @media screen and (min-width: 500px) {
    .home{
        .homeContent{
            .textDiv{
                .smallText{
                    font-size: 16px;
                }

                .homeTitle{
                    position: relative;
                    font-size: 2.3rem;
                    width: max-content;

                    &::after{
                        position: absolute;
                        content: '';
                        background: var(--SecondaryColor);
                        height: 5px;
                        width: 145px;
                        right: 0;
                        bottom: 5px;
                        z-index: -1;
                    }
                }
            }

            .cardDiv{
                grid-template-columns: repeat(2,1fr);
            }

            // .main .secContent {
            //     display: grid;
            //     grid-template-columns: repeat(1, minmax(0, 1fr));
            //     justify-content: center;
            //     gap: 1.5rem;
            // }
        }
    }
    
  }


  @media screen and (min-width: 660px) {

    .home{
        .homeContent{
            .cardDiv{
                padding: 2rem 1rem;
                grid-template-columns: repeat(3,1fr);
            }
        }
    }
    
  }

@media screen and (min-width: 940px) {
    .home{
        height: 120vh;
    }   
}


@media screen and (min-width: 1240px) {
    .home{
        height: 95vh;

        .homeContent{
            padding-left: 0 ;
            padding-right: 0 ;

            .cardDiv{
                padding: 2rem 1rem;
                grid-template-columns: repeat(3,1fr);
                .input{
                    margin-bottom: 1rem;
                }

                .searchOptions{
                    bottom: -1rem;
                    padding: 8px 1rem;

                    span{
                        font-size: 17px;
                    }
                }
            }
        }
    }  
}